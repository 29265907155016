// import './Download.css';
import React, { useEffect } from 'react'
import icon from './LinearIcon.png'
import appIcon from './appIcon.png'

const Download = () => {


	useEffect(() => {
		// // Iterate through its children
		document.querySelector('#download-page-main').childNodes.forEach((child, index) => {

			child.style.filter = 'blur(10px)';
			child.style.opacity = '0';
			
			child.style.animation = `onStartBlur 0.5s ease-in-out forwards`;
			child.style.animationDelay = `${index * 0.075}s`;
		});
	}, [])

	return (
		<div id='download-page'>

			<div id='download-page-main'>
				{/* <img id='download-page-icon' src={icon} /> */}
				<img id='download-page-icon' src={appIcon} />
				<div id='download-page-header1'>Download Castr</div>
				<div id='download-page-description'>Available for download directly from Apple’s Mac App Store.</div>
				<div id='download-page-description' style={{color: 'rgb(138, 143, 152)'}}>Or you can manually download it.</div>

				<MacStoreBadge />

				<div id='download-page-subheader'>Requires MacOS 13.0 or higher</div>
				<div 
					id='download-page-otherlink'
					onClick={() => window.open('https://github.com/castrapp/castr/releases/tag/v1.0')}
				>Or download outside the Mac App Store here</div>
			</div>
        </div>
	)
}

export { Download }



const navigateTo = (path) => {
    const baseUrl = window.location.origin;
    window.location.href = `${baseUrl}${path}`;
}





const MacStoreBadge = () => (
	<svg 
		xmlns="http://www.w3.org/2000/svg" 
		width="195.19" 
		height="50.017" 
		viewBox="0 0 195.19 50.017"
		id="mac-store-badge"
		onClick={() => window.open('https://apple.co/4ehwIXh')}
	>
		<g id="Group_209" data-name="Group 209" transform="translate(-791.405 -1174.983)">
			<path d="M183.275,0H11.922c-.459,0-.912,0-1.369,0-.383,0-.763.01-1.149.016A16.524,16.524,0,0,0,6.9.239a8.334,8.334,0,0,0-2.377.784A8.05,8.05,0,0,0,2.5,2.5,7.826,7.826,0,0,0,1.025,4.524,8.254,8.254,0,0,0,.243,6.9a16.247,16.247,0,0,0-.224,2.5c-.012.383-.013.768-.019,1.151v28.9c.006.388.007.764.019,1.153a16.246,16.246,0,0,0,.224,2.5A8.237,8.237,0,0,0,1.025,45.5,7.762,7.762,0,0,0,2.5,47.517a7.846,7.846,0,0,0,2.024,1.474A8.379,8.379,0,0,0,6.9,49.78,16.824,16.824,0,0,0,9.4,50c.386.009.766.013,1.149.013.457,0,.91,0,1.369,0H183.275c.449,0,.906,0,1.355,0,.381,0,.772,0,1.153-.013a16.6,16.6,0,0,0,2.5-.221,8.508,8.508,0,0,0,2.386-.789,7.849,7.849,0,0,0,2.022-1.474A8,8,0,0,0,194.17,45.5a8.258,8.258,0,0,0,.774-2.381,16.889,16.889,0,0,0,.232-2.5c0-.388,0-.765,0-1.153.01-.454.01-.906.01-1.368V11.924c0-.458,0-.912-.01-1.365,0-.383,0-.768,0-1.152a16.893,16.893,0,0,0-.232-2.5,8.274,8.274,0,0,0-.774-2.38,8.085,8.085,0,0,0-3.5-3.5,8.462,8.462,0,0,0-2.386-.784,16.31,16.31,0,0,0-2.5-.221C185.4.012,185.011,0,184.63,0c-.449,0-.906,0-1.355,0Z" transform="translate(791.405 1174.983)" fill="#a6a6a6"/>
			<path d="M10.341,48.7c-.381,0-.753,0-1.131-.013a15.865,15.865,0,0,1-2.337-.2A7.357,7.357,0,0,1,4.8,47.8,6.759,6.759,0,0,1,3.055,46.53a6.653,6.653,0,0,1-1.276-1.746A7.155,7.155,0,0,1,1.1,42.711a15.522,15.522,0,0,1-.208-2.345C.883,40.1.873,39.225.873,39.225V10.34s.011-.865.018-1.119A15.468,15.468,0,0,1,1.1,6.88,7.2,7.2,0,0,1,1.778,4.8,6.719,6.719,0,0,1,3.047,3.054,6.959,6.959,0,0,1,4.8,1.776,7.281,7.281,0,0,1,6.868,1.1,15.738,15.738,0,0,1,9.213.89L10.342.875H184.406l1.142.016a15.487,15.487,0,0,1,2.324.2,7.426,7.426,0,0,1,2.089.685,7,7,0,0,1,3.02,3.026,7.205,7.205,0,0,1,.669,2.062,16.252,16.252,0,0,1,.217,2.36c0,.354,0,.734,0,1.113.01.469.01.915.01,1.365v26.17c0,.454,0,.9-.01,1.344,0,.407,0,.779,0,1.163a15.92,15.92,0,0,1-.214,2.318,7.177,7.177,0,0,1-.675,2.088,6.853,6.853,0,0,1-1.27,1.733,6.768,6.768,0,0,1-1.75,1.279,7.33,7.33,0,0,1-2.086.687,15.683,15.683,0,0,1-2.337.2c-.366.009-.75.013-1.122.013l-1.355,0Z" transform="translate(791.623 1175.203)"/>
			<path d="M28.474,21.885a6.188,6.188,0,0,1,2.947-5.192A6.334,6.334,0,0,0,26.43,14c-2.1-.22-4.135,1.256-5.205,1.256-1.091,0-2.738-1.235-4.512-1.2a6.646,6.646,0,0,0-5.593,3.411c-2.418,4.187-.614,10.34,1.7,13.725,1.159,1.657,2.514,3.508,4.286,3.443,1.734-.072,2.382-1.106,4.476-1.106,2.074,0,2.682,1.106,4.49,1.064,1.861-.03,3.034-1.665,4.152-3.338a13.707,13.707,0,0,0,1.9-3.867A5.98,5.98,0,0,1,28.474,21.885Z" transform="translate(793.902 1178.483)" fill="#fff"/>
			<path d="M22.85,13.085A6.093,6.093,0,0,0,24.244,8.72,6.2,6.2,0,0,0,20.233,10.8,5.8,5.8,0,0,0,18.8,15,5.126,5.126,0,0,0,22.85,13.085Z" transform="translate(796.11 1177.167)" fill="#fff"/>
			<path d="M48.854,33.606V22.177h-.076L44.1,33.487H42.311l-4.693-11.31h-.075V33.606h-2.2V18.078h2.788l5.025,12.256h.085l5.015-12.256h2.8V33.606Z" transform="translate(800.257 1179.511)" fill="#fff"/>
			<path d="M49.4,29.574c0-1.979,1.517-3.175,4.208-3.336l3.1-.172V25.2c0-1.259-.829-1.97-2.239-1.97a2.164,2.164,0,0,0-2.378,1.594H49.913c.065-2.045,1.97-3.5,4.616-3.5,2.7,0,4.488,1.474,4.488,3.7v7.759H56.789V30.929h-.054a4.047,4.047,0,0,1-3.573,2.056A3.432,3.432,0,0,1,49.4,29.574ZM56.7,28.551V27.68l-2.788.172c-1.388.087-2.174.689-2.174,1.657,0,.99.818,1.636,2.066,1.636a2.714,2.714,0,0,0,2.9-2.594Z" transform="translate(803.774 1180.325)" fill="#fff"/>
			<path d="M66.5,25.369a2.5,2.5,0,0,0-2.668-2.087c-1.786,0-2.97,1.5-2.97,3.853,0,2.41,1.194,3.862,2.99,3.862A2.436,2.436,0,0,0,66.5,28.964h2.238a4.524,4.524,0,0,1-4.906,3.971c-3.229,0-5.337-2.207-5.337-5.8,0-3.519,2.109-5.8,5.316-5.8a4.551,4.551,0,0,1,4.908,4.035Z" transform="translate(806.052 1180.322)" fill="#fff"/>
			<path d="M80.731,29.409H74.813l-1.421,4.2H70.884L76.49,18.078h2.6L84.7,33.606h-2.55Zm-5.306-1.937h4.692L77.8,20.661H77.74Z" transform="translate(809.155 1179.511)" fill="#fff"/>
			<path d="M93.752,27.128c0,3.518-1.883,5.778-4.724,5.778a3.838,3.838,0,0,1-3.562-1.981h-.054v5.607H83.088V21.467h2.249v1.883h.043a4.016,4.016,0,0,1,3.6-2C91.858,21.348,93.752,23.619,93.752,27.128Zm-2.388,0c0-2.292-1.184-3.8-2.992-3.8-1.775,0-2.97,1.539-2.97,3.8,0,2.281,1.194,3.809,2.97,3.809,1.807,0,2.992-1.5,2.992-3.809Z" transform="translate(812.211 1180.329)" fill="#fff"/>
			<path d="M103.717,27.128c0,3.518-1.883,5.778-4.724,5.778a3.838,3.838,0,0,1-3.562-1.981h-.054v5.607H93.053V21.467H95.3v1.883h.043a4.016,4.016,0,0,1,3.6-2C101.823,21.348,103.717,23.619,103.717,27.128Zm-2.388,0c0-2.292-1.184-3.8-2.992-3.8-1.775,0-2.97,1.539-2.97,3.8,0,2.281,1.194,3.809,2.97,3.809,1.807,0,2.992-1.5,2.992-3.809Z" transform="translate(814.707 1180.329)" fill="#fff"/>
			<path d="M108.644,29.331c.172,1.54,1.668,2.551,3.712,2.551,1.959,0,3.368-1.011,3.368-2.4,0-1.205-.85-1.927-2.862-2.421l-2.012-.485c-2.851-.689-4.175-2.022-4.175-4.186,0-2.679,2.335-4.519,5.65-4.519,3.281,0,5.53,1.84,5.606,4.519h-2.346c-.14-1.55-1.421-2.485-3.293-2.485s-3.153.946-3.153,2.324c0,1.1.818,1.744,2.82,2.238l1.711.42c3.186.753,4.51,2.033,4.51,4.3,0,2.905-2.314,4.724-5.994,4.724-3.444,0-5.769-1.777-5.919-4.585Z" transform="translate(818.015 1179.459)" fill="#fff"/>
			<path d="M120.6,19.3v2.679h2.153v1.84H120.6V30.06c0,.97.431,1.421,1.377,1.421a7.261,7.261,0,0,0,.764-.054v1.829a6.381,6.381,0,0,1-1.291.107c-2.292,0-3.186-.861-3.186-3.056V23.819H116.62v-1.84h1.646V19.3Z" transform="translate(820.608 1179.817)" fill="#fff"/>
			<path d="M122.521,27.131c0-3.562,2.1-5.8,5.369-5.8s5.37,2.238,5.37,5.8-2.077,5.8-5.37,5.8S122.521,30.7,122.521,27.131Zm8.372,0c0-2.443-1.12-3.886-3-3.886s-3,1.453-3,3.886c0,2.453,1.12,3.884,3,3.884s3-1.431,3-3.884Z" transform="translate(822.086 1180.325)" fill="#fff"/>
			<path d="M132.643,21.467h2.216v1.927h.054a2.7,2.7,0,0,1,2.723-2.045,3.584,3.584,0,0,1,.8.087v2.174a3.249,3.249,0,0,0-1.044-.14,2.342,2.342,0,0,0-2.421,2.6v6.715h-2.324Z" transform="translate(824.621 1180.329)" fill="#fff"/>
			<path d="M147.885,29.466c-.313,2.055-2.314,3.466-4.875,3.466-3.293,0-5.337-2.207-5.337-5.747s2.055-5.854,5.24-5.854c3.132,0,5.1,2.152,5.1,5.584v.8h-8v.14a2.948,2.948,0,0,0,3.045,3.207,2.561,2.561,0,0,0,2.614-1.592Zm-7.855-3.379h5.66a2.723,2.723,0,0,0-2.777-2.873,2.866,2.866,0,0,0-2.883,2.873Z" transform="translate(825.88 1180.325)" fill="#fff"/>
			<path d="M38.366,8.733a3.3,3.3,0,0,1,3.511,3.707c0,2.384-1.288,3.754-3.511,3.754H35.671V8.733ZM36.83,15.139h1.407A2.346,2.346,0,0,0,40.7,12.455a2.352,2.352,0,0,0-2.461-2.668H36.83Z" transform="translate(800.337 1177.168)" fill="#fff"/>
			<path d="M41.683,13.029a2.667,2.667,0,1,1,5.311,0,2.668,2.668,0,1,1-5.311,0Zm4.168,0c0-1.22-.548-1.934-1.511-1.934s-1.509.714-1.509,1.934.543,1.939,1.509,1.939,1.511-.713,1.511-1.939Z" transform="translate(801.84 1177.515)" fill="#fff"/>
			<path d="M52.826,15.825H51.674L50.51,11.678h-.088l-1.159,4.147H48.121l-1.552-5.631H47.7l1.009,4.3h.083l1.158-4.3h1.066l1.158,4.3h.088l1-4.3h1.111Z" transform="translate(803.066 1177.536)" fill="#fff"/>
			<path d="M53.854,10.218h1.07v.894h.083a1.686,1.686,0,0,1,1.68-1A1.831,1.831,0,0,1,58.635,12.2v3.645H57.524V12.482c0-.9-.393-1.355-1.215-1.355a1.292,1.292,0,0,0-1.344,1.427v3.294H53.854Z" transform="translate(804.891 1177.514)" fill="#fff"/>
			<path d="M59.094,8.437H60.2v7.829H59.094Z" transform="translate(806.203 1177.096)" fill="#fff"/>
			<path d="M61.22,13.029a2.667,2.667,0,1,1,5.311,0,2.668,2.668,0,1,1-5.311,0Zm4.168,0c0-1.22-.548-1.934-1.511-1.934s-1.509.714-1.509,1.934.543,1.939,1.509,1.939,1.511-.713,1.511-1.939Z" transform="translate(806.732 1177.515)" fill="#fff"/>
			<path d="M66.4,14.255c0-1.014.755-1.6,2.094-1.681l1.525-.088V12c0-.595-.393-.93-1.153-.93-.62,0-1.05.228-1.173.626H66.618c.114-.967,1.023-1.587,2.3-1.587,1.412,0,2.208.7,2.208,1.892v3.847h-1.07v-.791h-.088a1.894,1.894,0,0,1-1.691.884A1.7,1.7,0,0,1,66.4,14.255Zm3.619-.481V13.3l-1.375.088c-.775.052-1.127.316-1.127.812s.44.8,1.044.8a1.327,1.327,0,0,0,1.458-1.231Z" transform="translate(808.032 1177.515)" fill="#fff"/>
			<path d="M71.348,13.448c0-1.779.915-2.906,2.337-2.906a1.856,1.856,0,0,1,1.727.988h.083V8.437h1.111v7.829H75.541v-.89h-.088a1.954,1.954,0,0,1-1.768.982C72.253,16.358,71.348,15.231,71.348,13.448Zm1.148,0c0,1.194.563,1.913,1.5,1.913s1.515-.729,1.515-1.908S74.93,11.54,74,11.54s-1.5.724-1.5,1.908Z" transform="translate(809.271 1177.096)" fill="#fff"/>
			<path d="M79.232,13.029a2.668,2.668,0,1,1,5.311,0,2.668,2.668,0,1,1-5.311,0Zm4.168,0c0-1.22-.548-1.934-1.511-1.934s-1.509.714-1.509,1.934.543,1.939,1.509,1.939,1.511-.713,1.511-1.939Z" transform="translate(811.243 1177.515)" fill="#fff"/>
			<path d="M84.669,10.218h1.07v.894h.083a1.686,1.686,0,0,1,1.68-1A1.831,1.831,0,0,1,89.451,12.2v3.645H88.34V12.482c0-.9-.393-1.355-1.215-1.355a1.292,1.292,0,0,0-1.344,1.427v3.294H84.669Z" transform="translate(812.607 1177.514)" fill="#fff"/>
			<path d="M93.917,9.074V10.5h1.22v.936h-1.22v2.9c0,.59.243.848.8.848a3.709,3.709,0,0,0,.424-.026v.926a3.645,3.645,0,0,1-.6.057c-1.236,0-1.728-.435-1.728-1.52v-3.18h-.894V10.5H92.8V9.074Z" transform="translate(814.421 1177.256)" fill="#fff"/>
			<path d="M95.7,8.437h1.1v3.1h.088a1.733,1.733,0,0,1,1.717-1.009,1.855,1.855,0,0,1,1.939,2.1v3.635H99.438V12.9c0-.9-.419-1.355-1.2-1.355a1.315,1.315,0,0,0-1.418,1.427v3.288H95.7Z" transform="translate(815.371 1177.096)" fill="#fff"/>
			<path d="M105.776,14.327a2.286,2.286,0,0,1-2.44,1.629,2.557,2.557,0,0,1-2.6-2.906,2.6,2.6,0,0,1,2.6-2.942c1.567,0,2.512,1.07,2.512,2.838v.388h-3.976V13.4a1.488,1.488,0,0,0,1.5,1.613,1.35,1.35,0,0,0,1.34-.683Zm-3.909-1.815h2.844a1.359,1.359,0,0,0-1.386-1.459,1.44,1.44,0,0,0-1.458,1.459Z" transform="translate(816.624 1177.515)" fill="#fff"/>
		</g>
	</svg>
)