// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.badge {
    border-radius: 9999px;
    height: 24px;
    color: oklch(0.717 0.1648 250.794);
    background-color: oklch(0.2545 0.0811 255.8);
    /* border: solid 1px oklch(0.717 0.1648 250.794); */
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 500;
    white-space: nowrap;
    gap: 8px;

    
    /* height: 31px; */
    /* border: solid red 1px; */
    /* width: fit-content; */
    padding: 0px 12px;
   

    

    white-space: nowrap;
    margin-bottom: 10px;

    /* overflow: hidden; */
}

.badge svg{
    /* min-width: 16px; */
}


.badge > div {
    /* border: solid red 1px; */
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;


    
       /* max-width: 100px !important; */

    /* flex-grow: 0; */
    /* flex-basis: 0; */
    /* overflow: hidden; */

    /* box-sizing: border-box; */
    
    /* animation: badgeScale 3s ease-in-out forwards; */
}


`, "",{"version":3,"sources":["webpack://./src/Common/Badges.css"],"names":[],"mappings":";AACA;IACI,qBAAqB;IACrB,YAAY;IACZ,kCAAkC;IAClC,4CAA4C;IAC5C,mDAAmD;IACnD,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,eAAe;IACf,gBAAgB;IAChB,mBAAmB;IACnB,QAAQ;;;IAGR,kBAAkB;IAClB,2BAA2B;IAC3B,wBAAwB;IACxB,iBAAiB;;;;;IAKjB,mBAAmB;IACnB,mBAAmB;;IAEnB,sBAAsB;AAC1B;;AAEA;IACI,qBAAqB;AACzB;;;AAGA;IACI,2BAA2B;IAC3B,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,QAAQ;;;;OAIL,iCAAiC;;IAEpC,kBAAkB;IAClB,mBAAmB;IACnB,sBAAsB;;IAEtB,4BAA4B;;IAE5B,mDAAmD;AACvD","sourcesContent":["\n.badge {\n    border-radius: 9999px;\n    height: 24px;\n    color: oklch(0.717 0.1648 250.794);\n    background-color: oklch(0.2545 0.0811 255.8);\n    /* border: solid 1px oklch(0.717 0.1648 250.794); */\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    font-size: 12px;\n    font-weight: 500;\n    white-space: nowrap;\n    gap: 8px;\n\n    \n    /* height: 31px; */\n    /* border: solid red 1px; */\n    /* width: fit-content; */\n    padding: 0px 12px;\n   \n\n    \n\n    white-space: nowrap;\n    margin-bottom: 10px;\n\n    /* overflow: hidden; */\n}\n\n.badge svg{\n    /* min-width: 16px; */\n}\n\n\n.badge > div {\n    /* border: solid red 1px; */\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    gap: 8px;\n\n\n    \n       /* max-width: 100px !important; */\n\n    /* flex-grow: 0; */\n    /* flex-basis: 0; */\n    /* overflow: hidden; */\n\n    /* box-sizing: border-box; */\n    \n    /* animation: badgeScale 3s ease-in-out forwards; */\n}\n\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
