// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#home {
    /* border: solid red 1px; */
    box-sizing: border-box;
    overflow: hidden;
}



`, "",{"version":3,"sources":["webpack://./src/Pages/Home2/Home.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,sBAAsB;IACtB,gBAAgB;AACpB","sourcesContent":["#home {\n    /* border: solid red 1px; */\n    box-sizing: border-box;\n    overflow: hidden;\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
