// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.button1 {
    all: unset;
    background-color: rgb(237, 237, 237);
    align-items: center;
    border-radius: 9999px;
    cursor: pointer;
    color: black;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    height: 32px;
    justify-content: center;
    padding: 0px 12px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    -webkit-user-select: none;
    gap: 4px;

    transition: all 250ms;
}

.button1 div {
    height: 1.5px;
    background-color: black;
    /* position: absolute; */
}

    .button1 > div {
       width: 10px;
       position: relative;
       display: flex;
       align-items: center;
       justify-content: flex-end;

       transition: width cubic-bezier(0.55, 0, 0.17, 1) 500ms;
    }

    .button1:hover > div {
        width: 20px;
    }

    .button1 > div > div {
        width: 7px;
        position: absolute;
        transform-origin: center right;
    }

    .button1 > div > div:nth-child(1) {
        transform: translateY(0.5px) rotateZ(45deg);
    }

    .button1 > div > div:nth-child(2) {
        transform: translateY(-0.5px) rotateZ(-45deg);
    }

.button1:hover {
    opacity: 0.85;
}`, "",{"version":3,"sources":["webpack://./src/Common/Button.css"],"names":[],"mappings":";AACA;IACI,UAAU;IACV,oCAAoC;IACpC,mBAAmB;IACnB,qBAAqB;IACrB,eAAe;IACf,YAAY;IACZ,gBAAgB;IAChB,eAAe;IACf,aAAa;IACb,YAAY;IACZ,uBAAuB;IACvB,iBAAiB;IACjB,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,iBAAiB;IACjB,yBAAyB;IACzB,QAAQ;;IAER,qBAAqB;AACzB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,wBAAwB;AAC5B;;IAEI;OACG,WAAW;OACX,kBAAkB;OAClB,aAAa;OACb,mBAAmB;OACnB,yBAAyB;;OAEzB,sDAAsD;IACzD;;IAEA;QACI,WAAW;IACf;;IAEA;QACI,UAAU;QACV,kBAAkB;QAClB,8BAA8B;IAClC;;IAEA;QACI,2CAA2C;IAC/C;;IAEA;QACI,6CAA6C;IACjD;;AAEJ;IACI,aAAa;AACjB","sourcesContent":["\n.button1 {\n    all: unset;\n    background-color: rgb(237, 237, 237);\n    align-items: center;\n    border-radius: 9999px;\n    cursor: pointer;\n    color: black;\n    font-weight: 500;\n    font-size: 14px;\n    display: flex;\n    height: 32px;\n    justify-content: center;\n    padding: 0px 12px;\n    position: relative;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    user-select: none;\n    -webkit-user-select: none;\n    gap: 4px;\n\n    transition: all 250ms;\n}\n\n.button1 div {\n    height: 1.5px;\n    background-color: black;\n    /* position: absolute; */\n}\n\n    .button1 > div {\n       width: 10px;\n       position: relative;\n       display: flex;\n       align-items: center;\n       justify-content: flex-end;\n\n       transition: width cubic-bezier(0.55, 0, 0.17, 1) 500ms;\n    }\n\n    .button1:hover > div {\n        width: 20px;\n    }\n\n    .button1 > div > div {\n        width: 7px;\n        position: absolute;\n        transform-origin: center right;\n    }\n\n    .button1 > div > div:nth-child(1) {\n        transform: translateY(0.5px) rotateZ(45deg);\n    }\n\n    .button1 > div > div:nth-child(2) {\n        transform: translateY(-0.5px) rotateZ(-45deg);\n    }\n\n.button1:hover {\n    opacity: 0.85;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
