// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!./Badges.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!./Button.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Cross Icon - Start */
.cross {
    --length: clamp(14px ,calc(14px + (21 - 14) * (100vw - 350px) / (1080 - 350)), 21px);
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    position: absolute;
    width: var(--length);
    height: var(--length);
    z-index: 2;
}

.cross div {
    --height: clamp(1px ,calc(1px + (1.5 - 1) * (100vw - 350px) / (1080 - 350)), 1.5px);
    height: var(--height);
    width: 100%;
    box-sizing: border-box;
    background-color: rgb(135, 135, 135);
    position: absolute;
}

.cross > div:nth-child(1) {
    transform: rotate(90deg);
}
/* Cross Icon - End */




/* Header 1 - Start */
.h1 {
    font-size: clamp(0px ,calc(30px + (72 - 30) * (100vw - 350px) / (1080 - 350)), 72px);
    font-weight: 600;
    text-align: center;
    line-height: 1;
    margin-bottom: 20px;
}
/* Header 2 - Start */




/* Header 2 - Start */
.h2 {
    font-size: clamp(0px ,calc(22px + (36 - 22) * (100vw - 350px) / (1080 - 350)), 36px);
    font-weight: 600;
    color: rgba(255, 255, 255, 0.5);
    text-align: center;
    padding: 0px 20px;
    /* line-height: 1.25; */
}

.h2 span {
    color: rgb(255, 255, 255);
}
/* Header 2 - End */




`, "",{"version":3,"sources":["webpack://./src/Common/common.css"],"names":[],"mappings":"AAMA,uBAAuB;AACvB;IACI,oFAAoF;IACpF,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;IACtB,kBAAkB;IAClB,oBAAoB;IACpB,qBAAqB;IACrB,UAAU;AACd;;AAEA;IACI,mFAAmF;IACnF,qBAAqB;IACrB,WAAW;IACX,sBAAsB;IACtB,oCAAoC;IACpC,kBAAkB;AACtB;;AAEA;IACI,wBAAwB;AAC5B;AACA,qBAAqB;;;;;AAKrB,qBAAqB;AACrB;IACI,oFAAoF;IACpF,gBAAgB;IAChB,kBAAkB;IAClB,cAAc;IACd,mBAAmB;AACvB;AACA,qBAAqB;;;;;AAKrB,qBAAqB;AACrB;IACI,oFAAoF;IACpF,gBAAgB;IAChB,+BAA+B;IAC/B,kBAAkB;IAClB,iBAAiB;IACjB,uBAAuB;AAC3B;;AAEA;IACI,yBAAyB;AAC7B;AACA,mBAAmB","sourcesContent":["@import url('./Badges.css');\n@import url('./Button.css');\n\n\n\n\n/* Cross Icon - Start */\n.cross {\n    --length: clamp(14px ,calc(14px + (21 - 14) * (100vw - 350px) / (1080 - 350)), 21px);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    box-sizing: border-box;\n    position: absolute;\n    width: var(--length);\n    height: var(--length);\n    z-index: 2;\n}\n\n.cross div {\n    --height: clamp(1px ,calc(1px + (1.5 - 1) * (100vw - 350px) / (1080 - 350)), 1.5px);\n    height: var(--height);\n    width: 100%;\n    box-sizing: border-box;\n    background-color: rgb(135, 135, 135);\n    position: absolute;\n}\n\n.cross > div:nth-child(1) {\n    transform: rotate(90deg);\n}\n/* Cross Icon - End */\n\n\n\n\n/* Header 1 - Start */\n.h1 {\n    font-size: clamp(0px ,calc(30px + (72 - 30) * (100vw - 350px) / (1080 - 350)), 72px);\n    font-weight: 600;\n    text-align: center;\n    line-height: 1;\n    margin-bottom: 20px;\n}\n/* Header 2 - Start */\n\n\n\n\n/* Header 2 - Start */\n.h2 {\n    font-size: clamp(0px ,calc(22px + (36 - 22) * (100vw - 350px) / (1080 - 350)), 36px);\n    font-weight: 600;\n    color: rgba(255, 255, 255, 0.5);\n    text-align: center;\n    padding: 0px 20px;\n    /* line-height: 1.25; */\n}\n\n.h2 span {\n    color: rgb(255, 255, 255);\n}\n/* Header 2 - End */\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
