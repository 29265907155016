// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* @import url('./Pages/Home2/Hero/Hero.css'); */

.App {
	text-align: center;
}

* {
	/* border: solid red 1px; */
	font-family: ui-sans-serif, -apple-system, BlinkMacSystemFont, "Inter", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

a {
	text-decoration: none;
}

html {
	/* overflow: hidden; */
	box-sizing: border-box;
	height: 100svh;
	overscroll-behavior: none; 
    /* touch-action: pan-x pan-y; */
	margin: 0;
	padding: 0;
	/* background-color: #141414; */
	background-color: #000000;
	color: white;
}


body {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	height: 100%;
	/* overscroll-behavior: none; */
    /* touch-action: pan-x pan-y; */
	-webkit-font-smoothing: antialiased;
}



#root {
	position: relative;
	/* overflow-y: scroll; */
	/* height: 100%; */
	/* box-sizing: border-box; */
	/* overflow-x: hidden; */
	/* overflow: hidden; */
}

	#root[mobile-menu-opened] #home {
		pointer-events: none;
		user-select: none;
	}

#app {
	height: 100%;
	box-sizing: border-box;
}


`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA,gDAAgD;;AAEhD;CACC,kBAAkB;AACnB;;AAEA;CACC,2BAA2B;CAC3B,+HAA+H;AAChI;;AAEA;CACC,qBAAqB;AACtB;;AAEA;CACC,sBAAsB;CACtB,sBAAsB;CACtB,cAAc;CACd,yBAAyB;IACtB,+BAA+B;CAClC,SAAS;CACT,UAAU;CACV,+BAA+B;CAC/B,yBAAyB;CACzB,YAAY;AACb;;;AAGA;CACC,SAAS;CACT,UAAU;CACV,sBAAsB;CACtB,YAAY;CACZ,+BAA+B;IAC5B,+BAA+B;CAClC,mCAAmC;AACpC;;;;AAIA;CACC,kBAAkB;CAClB,wBAAwB;CACxB,kBAAkB;CAClB,4BAA4B;CAC5B,wBAAwB;CACxB,sBAAsB;AACvB;;CAEC;EACC,oBAAoB;EACpB,iBAAiB;CAClB;;AAED;CACC,YAAY;CACZ,sBAAsB;AACvB","sourcesContent":["/* @import url('./Pages/Home2/Hero/Hero.css'); */\n\n.App {\n\ttext-align: center;\n}\n\n* {\n\t/* border: solid red 1px; */\n\tfont-family: ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Inter\", \"Segoe UI\", Roboto, \"Helvetica Neue\", Arial, sans-serif;\n}\n\na {\n\ttext-decoration: none;\n}\n\nhtml {\n\t/* overflow: hidden; */\n\tbox-sizing: border-box;\n\theight: 100svh;\n\toverscroll-behavior: none; \n    /* touch-action: pan-x pan-y; */\n\tmargin: 0;\n\tpadding: 0;\n\t/* background-color: #141414; */\n\tbackground-color: #000000;\n\tcolor: white;\n}\n\n\nbody {\n\tmargin: 0;\n\tpadding: 0;\n\tbox-sizing: border-box;\n\theight: 100%;\n\t/* overscroll-behavior: none; */\n    /* touch-action: pan-x pan-y; */\n\t-webkit-font-smoothing: antialiased;\n}\n\n\n\n#root {\n\tposition: relative;\n\t/* overflow-y: scroll; */\n\t/* height: 100%; */\n\t/* box-sizing: border-box; */\n\t/* overflow-x: hidden; */\n\t/* overflow: hidden; */\n}\n\n\t#root[mobile-menu-opened] #home {\n\t\tpointer-events: none;\n\t\tuser-select: none;\n\t}\n\n#app {\n\theight: 100%;\n\tbox-sizing: border-box;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
